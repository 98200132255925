import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Movie } from '@usheru-hff/shared/domain';
import { ModalPictureService } from '../aplication-services/modal-picture.service';

@Directive({
  selector: 'img[modal]',
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[style.cursor]': '"pointer"'
  }
})
export class ImgModalDirective {
  constructor(private el: ElementRef, private readonly route: ActivatedRoute, private modalService: ModalPictureService) {}
  @Input() modal: { src: string; type: 'portrait' | 'landscape'; movie?: Movie; imgType: 'wallpaper-img' | 'poster-img' | 'tile-img' };

  @HostListener('click', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onClick(event: any) {
    const imgEl = event.target as HTMLImageElement;
    const alt = imgEl.alt;
    const title = imgEl.title;
    imgEl.setAttribute;
    this.modalService.setImage({
      fileName: title || alt,
      source: this.modal.src,
      alt: alt,
      type: this.modal.type,
      movie: this.modal.movie,
      imgType: this.modal.imgType
    });
  }
}
