import { Directive, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalPictureService } from '../aplication-services/modal-picture.service';

@Directive({
  selector: '[imgModalTransformer]'
})
export class ImgModalTransformerDirective {
  constructor(private el: ElementRef, private readonly route: ActivatedRoute, private modalService: ModalPictureService) {}

  @HostListener('click', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onClick(event: any) {
    const modal = event.target.hasAttribute('modal');
    if (event.target.tagName === 'IMG' && modal) {
      const imgEl = event.target as HTMLImageElement;
      const src = imgEl.src;
      const alt = imgEl.alt;
      const title = imgEl.title;
      imgEl.setAttribute;
      this.modalService.setImage({
        fileName: title || alt,
        source: src,
        alt: alt,
        type: 'custom',
        imgType: 'tile-img'
      });
    }
  }
}
