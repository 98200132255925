import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ImgType, Movie } from '@usheru-hff/shared/domain';

interface ImageData {
  fileName?: string;
  source: string | Movie;
  type: ImgType;
  alt: string;
  movie?: Movie;
  imgType: 'wallpaper-img' | 'poster-img' | 'tile-img';
}

@Injectable({
  providedIn: 'root'
})
export class ModalPictureService {
  image: BehaviorSubject<ImageData> = new BehaviorSubject<ImageData>(null);

  constructor() {}

  setImage(data: ImageData) {
    this.image.next(data);
  }

  cleanImage() {
    this.image.next(null);
  }
}
