import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'kebabCase'
})
export class KebabCasePipe implements PipeTransform {
  transform(value: string) {
    let newText = value.toString();
    newText = newText.toLowerCase().replace(/ /g, '-');
    // remove spaces before and after text
    return newText;
  }
}
