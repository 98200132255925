import { Injectable, EventEmitter } from '@angular/core';
import { Movie, PageLink } from '@usheru-hff/shared/domain';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  // custom branded page header observables
  private _brandedMovie = new BehaviorSubject<Movie | null>(null);
  private _brandedPageLinks = new BehaviorSubject<PageLink[]>([]);
  private _sectionSelected = new BehaviorSubject<string>('');
  private _brandedMobileMenuStyle: EventEmitter<string | null> = new EventEmitter<string | null>();

  constructor() {}

  setBrandedMovie(movie: Movie | null) {
    this._brandedMovie.next(movie);
  }

  setBrandedMobileMenuStyle(style: string | null) {
    this._brandedMobileMenuStyle.emit(style);
  }

  setBrandedPageLinks(x: PageLink[]) {
    this._brandedPageLinks.next(x);
  }

  getBrandedMovie(): Observable<Movie | null> {
    return this._brandedMovie.asObservable();
  }

  getBrandedPageLinks(): Observable<PageLink[]> {
    return this._brandedPageLinks.asObservable();
  }

  getSectionSelected(): Observable<string> {
    return this._sectionSelected.asObservable();
  }

  getBrandedMobileMenuStyle(): EventEmitter<string> {
    return this._brandedMobileMenuStyle;
  }

  setSectionSelected(x: string) {
    this._sectionSelected.next(x);
  }
}
