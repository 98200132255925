import { Inject, Pipe, PipeTransform } from '@angular/core';
import { ENVIRONMENT, Environment } from '@environment';
@Pipe({
  name: 'readMore'
})

// This pipe grabs a text and cut it with a given lenght, adds a read more btn that redirects to a given link also
export class ReadMorePipe implements PipeTransform {
  constructor(@Inject(ENVIRONMENT) private env: Environment) {}

  transform(value: string, params?: { link: string; numChar?: number }) {
    if (value == null || value.length <= 0) {
      return '';
    }

    let newText = '';
    const lenghtText = params?.numChar || this.env.readMoreLenght || 200;
    if (typeof lenghtText == 'number') {
      newText = value.slice(0, lenghtText) + '... ';
    } else if (lenghtText == 'first-sentence') {
      const breakpoint = 174;
      const segment = value.slice(breakpoint);

      // check this chars after the breakpoint 1
      let dotmark,
        endpparagraphmark = -1;
      if (segment.length > breakpoint) {
        dotmark = segment.indexOf('.');
        if (dotmark > -1) dotmark += breakpoint;
        endpparagraphmark = segment.indexOf('</p>');
        if (endpparagraphmark > -1) endpparagraphmark += breakpoint + 3;
      }

      // check this chars after the breakpoint 2
      let questionmark,
        exclamationmark = -1;
      if (segment.length > breakpoint) {
        questionmark = segment.indexOf('?');
        if (questionmark > -1) questionmark += breakpoint;
        exclamationmark = segment.indexOf('!');
        if (exclamationmark > -1) exclamationmark += breakpoint;
      }

      const sentenceEnd = [dotmark, endpparagraphmark, questionmark, exclamationmark].reduce((acc, cur) => {
        if (!acc) acc = -1;
        if (cur == -1) return acc;
        if (acc == -1) return cur;
        return acc < cur ? acc : cur;
      }, -1);

      newText = value.slice(0, sentenceEnd + 1);
    }

    if (params?.link) {
      newText = newText + `<a href="${params.link}" target="_blank" class="read-button">read more</a>`;
    }
    return newText;
  }
}
