import { Directive, Input, Optional, afterNextRender, OnInit } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';

@Directive({
  selector: '[externalLink]'
})
export class ExternalLinkCheckDirective implements OnInit {
  @Input() externalLink: string;
  constructor(
    // Inject RouterLinkWithHref
    @Optional() private link: RouterLinkWithHref
  ) {}

  ngOnInit(): void {
    if (!this.link) {
      return;
    }

    // Save original onClick method
    const onClick = this.link.onClick;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.link.onClick = (...args: any[]) => {
      if (this.externalLink) {
        // Process external url
        window.open(this.externalLink, '_blank');
        return false;
      } else {
        // Process internal url by calling routerLink original method
        return onClick.apply(this.link, args);
      }
    };
  }
}
