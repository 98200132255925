import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteTransformerDirective } from './directives/route-transformer.directive';
import { SafePipe } from './pipes/safe.pipe';
import { CammelCasePipe } from './pipes/cammel-case.pipe';
import { ReadMorePipe } from './pipes/read-more.pipe';
import { KebabCasePipe } from './pipes/kebab-case.pipe';
import { ImgModalTransformerDirective } from './directives/img-modal-transformer';
import { ExternalLinkCheckDirective } from './directives/external-link-check';
import { ImgModalDirective } from './directives/img-modal';
import { TemplatesService } from './aplication-services/templates.service';
import { TitleCaseExtendedPipe } from './pipes/title-case-extended.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    RouteTransformerDirective,
    ImgModalTransformerDirective,
    TitleCaseExtendedPipe,
    SafePipe,
    CammelCasePipe,
    ReadMorePipe,
    KebabCasePipe,
    ExternalLinkCheckDirective,
    ImgModalDirective
  ],
  exports: [
    RouteTransformerDirective,
    ImgModalTransformerDirective,
    TitleCaseExtendedPipe,
    SafePipe,
    CammelCasePipe,
    ReadMorePipe,
    KebabCasePipe,
    ExternalLinkCheckDirective,
    ImgModalDirective
  ],
  providers: [TemplatesService]
})
export class SharedUtilsModule {}
