import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
  private templates = {};
  /**
   * You can add templates to this service in order to make them available in the site
   * @param name It is the name of the template currently we use  'inCinemasAddon' | 'brandedInCinemasAddon' | 'backCardExtraDescription' as a fixed ones
   * but for any other template you can use the one you like;
   * @param template It is the template that will be used
   */
  add(name: string, template: TemplateRef<unknown>) {
    // preventing adding the same template multiple times
    if (this.templates[name]) {
      return;
    }

    this.templates[name] = template;
  }

  /**
   * @param name The name of the template
   * @returns the template if found or undefined
   */
  get(name: string): TemplateRef<unknown> {
    return this.templates[name];
  }

  getTemplates() {
    return this.templates;
  }
}
