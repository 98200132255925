import { Directive, ElementRef, HostListener, AfterViewInit, afterNextRender } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[routeTransformer]'
})
export class RouteTransformerDirective implements AfterViewInit {
  constructor(private el: ElementRef, private router: Router) {
    afterNextRender(() => {
      this.initActiveCustomLink();
    }); 
  }

  @HostListener('click', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onClick(event: any) {
    const anchorEl = event.target as HTMLAnchorElement;
    let href = anchorEl.getAttribute('href');

    if (anchorEl.tagName === 'A') {
      if (anchorEl.getAttribute('target') == '_blank') return;
      if (this.checkIfIsTheSameRoute(href)) {
        if (href.includes('#')) {
          event.preventDefault();
          const element = document.querySelector(`#${href.split('#')[1]}`) as HTMLElement;
          const headerOffset = document.querySelector('.header-wrapper')?.clientHeight;
          afterNextRender(() => {
            if (headerOffset) {
              const top: number = element.offsetTop + window.pageYOffset - headerOffset;
              window.scrollTo({ top, behavior: 'smooth' });
            }
          });
        }
        return;
      }
      if (href.indexOf('mailto:') == 0) return;
      //

      if (anchorEl.hasAttribute('can-activate')) {
        this.disableCustomActiveLinks();
        this.activeCustomLink(anchorEl);
      }
      //
      if (href.indexOf('/') === 0) href = href.slice(1);
      this.router.navigate([href]);
    } else {
      return;
    }
  }

  ngAfterViewInit(): void {}

  checkIfIsTheSameRoute(route: string): boolean {
    return route.indexOf(location.pathname) == 0;
  }

  initActiveCustomLink() {
    const activateLink = document.querySelectorAll<HTMLAnchorElement>('a[can-activate]');
    if (activateLink) {
      const currHref = location.href;
      activateLink.forEach(el => {
        el.classList.remove('active');
        if (currHref === el.href) {
          this.activeCustomLink(el);
        }
      });
    }
  }

  activeCustomLink(el: HTMLAnchorElement) {
    el.classList.add('active');
  }

  disableCustomActiveLinks() {
    document.querySelectorAll('a.active[can-activate]').forEach(el => el.classList.remove('active'));
  }
}
