import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'cammelCase'
})
export class CammelCasePipe implements PipeTransform {
  transform(value: string, text?: string) {
    let newText = value?.toString();

    let textBefore = '';
    let textAfter = '';
    if (text) {
      const initPos = newText.indexOf(text);
      const endPost = initPos + text.length;
      textBefore = newText.slice(0, initPos);
      textAfter = newText.slice(endPost);
      newText = text;
    }

    if (value == null || value.length <= 0) {
      return '';
    }

    const dashPos: number = value.toString().indexOf('-');
    if (dashPos > -1) {
      newText = newText.replace(/-/g, ' ');
    }

    newText = newText
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');

    const pos = newText.lastIndexOf('.');

    newText = newText.substring(0, pos + 1) + newText.charAt(pos + 1).toLowerCase() + newText.substring(pos + 2);

    return `${textBefore}${newText}${textAfter}`;
  }
}
