import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupCustomPageService {
  trigger = new BehaviorSubject<string>(null);

  pushTrigger(customPage: string) {
    this.trigger.next(customPage);
  }
}
