export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * It converts an epoch to a date string
 * @param dateStr epoch
 * @param locale locale string e.g. 'nl-NL'
 * @param options date time format options
 * @returns an stringifyed date
 * @author Favioz
 */
export function getDateName(dateStr: number, locale: string, options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' }) {
  const date = new Date(dateStr);
  return date.toLocaleDateString(locale, options);
}

/**
 * @date epoch date
 * @returns boolean, true if date is in the future
 */
export function isDateInTheFuture(date: number): boolean {
  return Date.now() < date;
}

/**
 *
 * @param date epoch date
 * @returns boolean, true if date is in the past
 */
export function isDateInthePast(date: number): boolean {
  return Date.now() > date;
}

/**
 *
 * @param from epoch from date
 * @param to epoch to date
 * @returns boolean, true if date range is in the present from < now < to
 */
export function isDateRangePresent(from: number, to: number): boolean {
  return from < Date.now() && Date.now() < to;
}

/**
 *
 * @param from epoch from date
 * @param to epoch to date
 * @returns boolean, true if date range is in the past
 */
export function isDateRangePast(from: number, to: number): boolean {
  return from < Date.now() && to < Date.now();
}

/**
 *
 * @param from epoch from date
 * @param to epoch to date
 * @returns boolean, true if date range is in the future
 */
export function isDateRangeFuture(from: number, to: number): boolean {
  return from > Date.now() && to > Date.now();
}

/**
 * Transforms a date string in the format dd-mm-yyyy to a Date object
 * @param date string date in the format dd-mm-yyyy
 * @returns
 */
export function getDateFromDDMMYYYY(date: string) {
  if (!date) return null;
  const splittedDate = date.split('-');
  return new Date(`${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`);
}

/**
 * Takes a date string as input in the format dd-mm-yyyy and returns string Today/Tomorrow/False accordingly
 * @param date string date in the format dd-mm-yyyy
 * @returns
 */
export function isTodayOrTomorrow(dateString: string): string | boolean {
  const today = new Date();
  const tomorrow = new Date();
  const incomingDateStr = new Date(dateString).toDateString();
  tomorrow.setDate(today.getDate() + 1);
  const todayStr = today.toDateString();
  const tomorrowStr = tomorrow.toDateString();
  if (incomingDateStr === todayStr) return 'today';
  else if (incomingDateStr === tomorrowStr) return 'tomorrow';
  else return false;
}

// Helper function to parse date from input format
function parseDate(dateString: string, format: string) {
  const parts = dateString.split(/[^0-9]+/);
  const formatParts = format.match(/(dd|mm|yyyy)/g);
  const dateInfo = { day: 0, month: 0, year: 0 };

  formatParts.forEach((part, index) => {
    if (part === 'dd') dateInfo.day = parseInt(parts[index], 10);
    else if (part === 'mm') dateInfo.month = parseInt(parts[index], 10) - 1; // Month is 0-based
    else if (part === 'yyyy') dateInfo.year = parseInt(parts[index], 10);
  });

  return new Date(dateInfo.year, dateInfo.month, dateInfo.day);
}

// Helper function to format date to output format
function formatDate(date: Date, format: string) {
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear().toString();
  return format.replace('dd', day).replace('mm', month).replace('yyyy', year);
}

export function dateFormatter(inputDate: string, inputFormat: string, outputFormat: string) {
  // Parse input date using input format
  const parsedDate = parseDate(inputDate, inputFormat);

  // Format parsed date using output format
  const formattedDate = formatDate(parsedDate, outputFormat);

  return formattedDate;
}

export function convertToISODate(dateString: string, time: string = '00:00:00') {
  // Parse the date and time components
  let [timePart, period] = time.split(" ");
  let [year, month, day] = dateString.split("-").map(Number);
  let [hours, minutes] = timePart.split(":").map(Number);

  // Convert to 24-hour format if the period is "PM" and the hour is less than 12
  if (period === "PM" && hours < 12) hours += 12;
  // Adjust for midnight case (12 AM)
  if (period === "AM" && hours === 12) hours = 0;

  // Format as ISO 8601
  return new Date(`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`);
}
